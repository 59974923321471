.box-project-s1 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 420px;
  cursor: pointer;

  .box-inside {
    position: absolute;
    @include flex-center-xy();
    @include transition(250ms);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 169, 241, 0.8);
    opacity: 0;
    z-index: 2;

    .text {
      display: inline-block;
      padding: 0 15px;
      width: 85%;
      color: #fff;
      text-align: center;
      @include transition(350ms);
      @include cp-property(transform, translateY(15px));

      .name {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.1;
        text-transform: uppercase;
      }

      .mini-descr {
        margin-top: 5px;
        font-size: 1.05rem;
        font-weight: 400;
        line-height: 1.3;
      }
    }
  }

  &:hover {
    .box-inside {
      opacity: 1;

      .text {
        @include cp-property(transform, translateY(0px));
      }
    }
  }

  @media screen and (max-width: 1699px) {
    .box-inside {
      .text {
        .name {
          font-size: 1.60rem;
        }

        .mini-descr {
          font-size: 0.99rem;
        }
      }
    }
  }
  @media screen and (max-width: 1499px) {
    height: 370px;
  }
  @media screen and (max-width: 1399px) {
    .box-inside {
      .text {
        .name {
          font-size: 1.40rem;
        }

        .mini-descr {
          margin-top: 4px;
          font-size: 0.90rem;
        }
      }
    }
  }
}
