.title-s1 {
  font-size: 2.05rem;
  font-weight: 700;

  &.c-blue {
    color: $blueSustam;
  }
  &.c-gray {
    color: #575757;
  }

  @media screen and (max-width: 575px) {
    font-size: 1.90rem;
  }
}
