#home-page {

	.banners-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-wrapper {
				.swiper-slide {
					.box-image {
						display: block;

						img {
							width: 100%;
						}
					}
				}
			}

			.swiper-button-prev, .swiper-button-next {
				margin-top: -25px;
				width: 50px;
				height: 84px;
				background-color: rgba(35, 169, 241, 0.2);

			 	&:after {
					font-size: 2.0rem;
				  font-weight: 600;
				  color: #23a9f1;
				}
			}

			.swiper-button-prev {
				left: 0;
				border-radius: 0 42px 42px 0;

				&:after {
					padding-right: 10px;
				}
			}
			.swiper-button-next {
				right: 0;
				border-radius: 42px 0 0 42px;

				&:after {
					padding-left: 8px;
				}
			}

			.swiper-pagination {
				.swiper-pagination-bullet {
					width: 10px;
					height: 10px;
				}

				.swiper-pagination-bullet-active {
					background-color: $blueSustam;
				}
			}
		}
	}

	.services-section {
		position: relative;
		padding: 56px 0;
		background-color: #f2fcff;

		.col-title {
			margin-bottom: 36px;
			text-align: center;
		}

		.col-service {
			text-align: center;

			.title {
				margin-bottom: 52px;
				color: #535353;
				font-size: 1.30rem;
				font-weight: 700;
			}

			.p-img {
				margin-bottom: 42px;

				img {
					max-width: 95%;
				}
			}

			.descr {
				color: #848788;
				font-size: 1.1rem;
				font-weight: 600;
				line-height: 1.2;
			}
		}

		@media screen and (max-width: 1299px) {
			.col-service {
				.title {
					margin-bottom: 46px;
				}

				.p-img {
					margin-bottom: 36px;

					img {
						max-height: 166px;
					}
				}

				.descr {
					font-size: 1.05rem;
				}
			}
		}
		@media screen and (min-width: 992px) and (max-width: 1199px) {
			padding: 48px 0;

			.col-service {
				.title {
					margin-bottom: 28px;
					font-size: 1.15rem;
				}

				.p-img {
					margin-bottom: 22px;

					img {
						max-height: 126px;
					}
				}

				.descr {
					font-size: 0.95rem;
				}
			}
		}
		@media screen and (max-width: 991px) {
			padding: 38px 0;

			.container {
				>.row {
					justify-content: center;
				}
			}

			.col-title {
				margin-bottom: 28px;
			}

			.col-service {
				margin-bottom: 30px;

				.title {
					margin-bottom: 14px;
				}

				.p-img {
					margin-bottom: 15px;

					img {
						max-height: 166px;
					}
				}

				.descr {
					font-size: 1.05rem;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding: 28px 0;

			.col-title {
				margin-bottom: 16px;
			}

			.col-service {
				.title {
					margin-bottom: 8px;
				}

				.p-img {
					margin-bottom: 10px;
				}
			}
		}
	}

	.portfolio-section {
		.box-categories {
			position: relative;
			padding: 15px 0;
			width: 100%;
			text-align: center;
			border-top: 1px solid #b2b5b7;
			border-bottom: 1px solid #b2b5b7;
			background-color: #f2fcff;

			.btn-category {
				position: relative;
				display: inline-block;
				padding: 10px 16px;
				margin: 0 6px;
				min-width: 108px;
				color: $blueSustam;
				cursor: pointer;
				font-size: 1.4rem;
				font-weight: 500;
				text-decoration: none !important;
				border-radius: 16px;
				border: 2px solid transparent;
				background-color: transparent;
				@include transition(150ms);

				&:hover {
					border-color: $blueSustam;
				}

				&.active {
					color: #ffffff !important;
					border-color: $blueSustam;
					background-color: $blueSustam;
				}
			}
		}

		.projects-container {
			.swiper {
				.swiper-button-prev, .swiper-button-next {
					color: #ffffff;

					&:after {
						font-size: 2.3rem;
					}
				}
				.swiper-button-prev {
					left: 18px;
				}
				.swiper-button-next {
					right: 18px;
				}
				.swiper-pagination {
					bottom: 14px;

					.swiper-pagination-bullet {
						background-color: #ffffff;
						opacity: 0.6;
					}
					.swiper-pagination-bullet-active {
						background-color: $blueSustam;
						opacity: 1;
					}
				}
			}

			.box-projects {
				position: relative;
				padding: 30px 15px;
				background-color: #ffffff;
			}
		}

		@media screen and (max-width: 767px) {
			.box-categories {
				.btn-category {
					padding: 5px 13px;
					margin: 0 3px;
					min-width: 86px;
					font-size: 1.05rem;
					border-radius: 10px;
				}
			}

			.projects-container {
				.box-projects {
					padding: 20px 8px;
				}
			}
		}
		@media screen and (max-width: 575px) {
			.box-categories {
				.btn-category {
					padding: 3px 10px;
					margin: 4px 0px;
					min-width: 52px;
					font-size: 0.90rem;
					border-radius: 7px;
					border-width: 1px;
				}
			}

			.projects-container {
				padding-left: 0;
				padding-right: 0;

				.box-projects {
					padding: 0px;
				}

				.swiper {
					.swiper-button-prev, .swiper-button-next {
						&:after {
							font-size: 2.1rem;
						}
					}

					.swiper-button-prev {
						left: 8px;
					}
					.swiper-button-next {
						right: 8px;
					}
				}
			}
		}
	}

	.customers-section {
		position: relative;
		padding: 56px 0;
		background-color: #ffffff;

		.container {
			>.row {
				justify-content: center;
			}
		}

		.col-title {
			margin-bottom: 40px;
			text-align: center;
		}

		.col-carousel {
			position: relative;

			.box-image {
				position: relative;
				@include flex-center-xy();
				flex: 0 0 100%;
				max-width: 100%;
				height: 140px;
				width: 100%;

				.img {
					display: inline-block;
					width: 100%;
					text-align: center;

					img {
						max-width: 100%;
						max-height: 140px;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next {
				&:after {
					color: #9ba0a1;
					font-size: 2.4rem;
					font-weight: 100;
				}
			}

			.swiper-button-prev {
				left: -40px;
			}
			.swiper-button-next {
				right: -40px;
			}
		}

		@media screen and (max-width: 1399px) {
			.col-title {
				margin-bottom: 25px;
			}
		}
		@media screen and (max-width: 991px) {
			padding: 38px 0;

			.col-title {
				margin-bottom: 12px;
			}

			.col-carousel {
				.swiper-button-prev {
					left: 0px;
				}
				.swiper-button-next {
					right: 0px;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding: 26px 0;

			.col-title {
				margin-bottom: 8px;
			}

			.col-carousel {
				.swiper-button-prev, .swiper-button-next {
					&:after {
						font-size: 1.9rem;
					}
				}

				.swiper-button-prev {
					left: 8px;
				}
				.swiper-button-next {
					right: 8px;
				}
			}
		}
	}

	.about-section {
		position: relative;
		padding: 25px 0;
		border-top: 2px solid #a1a1a1;
		background-color: #f0f0f0;

		.container {
			>.row {
				align-items: center;
			}
		}

		.col-image {
			img {
				max-width: 100%;
			}
		}

		.col-text {
			font-size: 1.25rem;
  		line-height: 1.3;
			text-align: center;

			.box-txt {
				position: relative;
				width: 510px;
				max-width: 100%;
				margin: 0 auto;
			}
		}

		@media screen and (max-width: 991px) {
			.col-text {
				order: 0;
			}

			.col-image {
				order: 1;
			}
		}
		@media screen and (max-width: 575px) {
			.col-text {
				margin-bottom: 18px;
				font-size: 1.10rem;
			}
		}
	}

}
