#footer {
  position: relative;
  padding: 42px 0;
  color: #ffffff;
  background-color: $blueSustam;

  a {
    display: inline-block;
    color: #ffffff;
  }

  .col-footer {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.95rem;

    h6 {
      font-weight: 600;
      letter-spacing: 2px;
    }
  }

  .col-first {
    align-self: center;

    .btn-logo {
      display: inline-block;

      img {
        max-height: 77px;
      }
    }

    .box-networks {
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      align-items: center;

      .txt {
        margin-right: 18px;
        font-size: 1.0rem;
        font-weight: 600;
      }

      .btn-network {
        margin-left: 8px;
        margin-right: 8px;
        font-size: 2.3rem;
        line-height: 1;

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }

  .col-info {
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    padding: 22px 0;

    .col-first {
      text-align: center;

      .box-networks {
        display: inline-flex;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
}
