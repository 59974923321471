#header {
	position: relative;
	width: 100%;

	.header-content {
		position: relative;

		.navbar {
			background-color: $blueSustam !important;

			.navbar-brand {
				img {
					max-height: 57px;
				}
			}

			.navbar-toggler {
				color: #ffffff !important;
				border: 0;
				font-size: 1.90rem;

				.navbar-toggler-icon {
				}
			}

			.navbar-collapse {
				.navbar-nav {
					.nav-item {
						.nav-link {
							color: #ffffff;
							font-weight: 500;

							&:hover {
								color: #e3e1e1;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				.navbar-collapse {
					.navbar-nav {
						.nav-item {
							margin-right: 1.6rem;
							margin-left: 1.6rem;
							font-size: 1.25rem;
						}
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.navbar {
					.navbar-collapse {
						.navbar-nav {
							.nav-item {
								margin-right: 0.6rem;
						    margin-left: 0.6rem;
						    font-size: 1.10rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				.navbar-brand {
					padding-top: 2px;
  				padding-bottom: 2px;

					img {
						max-height: 40px;
					}
				}

				.navbar-collapse {
					.navbar-nav {
						.nav-item {
							.nav-link {
								padding-top: 3px;
  							padding-bottom: 3px;
							}
						}
					}
				}
			}
		}
	}
}
